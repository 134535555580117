import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import OneColumnPageLayout from "../components/layouts/OneColumnPageLayout"

const paragraph = 'font-body text-lg text-gray-900 mb-4'

const Join = () => (
  <MainContainer>
    <HeadData
      title='Join Alumni Chapter'
      description='Register and join the Orange County Sigma Chi Alumni Chapter.'
    />

    <OneColumnPageLayout>
      <h2 className='font-display text-4xl text-blue-900 mb-8 leading-tight'>Join our Sigma Chi Alumni Chapter</h2>

      <p className={paragraph}>Please complete the form below to join the alumni chapter and pay your alumni dues online. Please note that * indicates a required field.</p>

      <p className={paragraph}>[Embed payments form here.]</p>
    </OneColumnPageLayout>
  </MainContainer>
)

export default Join
